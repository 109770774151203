import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import InputMask from 'react-input-mask';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { Modal, message, Button } from 'antd';
import { HandIcon } from './svg';
import { getCallRecordQuery } from './util';
import commonSx from './styles';
import { UPDATE_PHONE, UPDATE_CALL_RECORD, SAVE_CALL_TO_ACTIVITY } from './queries';

const sx = StyleSheet.create({
  flex: {
    display: 'flex',
  },
  mtb10: {
    margin: '10px 0px',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  width100: {
    width: '100%',
  },
  flex1: {
    flex: 1,
  },
  saveNumberContainer: {
    position: 'relative',
    marginLeft: '10px',
  },
  otherStyles: {
    border: '1px solid #EAEAEA',
    borderRadius: '6px',
    padding: '1.3rem',
    fontSize: '1.5rem',
    outline: 'none',
    color: '#1E1E34',
  },
  inputError: {
    background: '#FFF5F5',
    border: '1px solid #B50D0D',
  },
  inputLabelError: {
    color: '#B50D0D',
  },
  actionBtn: {
    margin: '0px 10px',
  },
  cancelBtn: {
    border: '1px solid purple',
    color: 'purple',
  },
  numberContainer: {
    marginRight: '10px',
    flex: 3,
  },
  form: {
    flexDirection: 'column',
    color: 'black',
  },
  pb80: {
    paddingBottom: '80px',
  },
});

const SaveNumber = ({
  callId,
  saveCallOnly,
  number,
  patient,
  patientId,
  onCancel,
  isModalOpen,
  onSuccessSaveNumber,
  afterClose,
  isPostCall,
  setSaveCallOnly,
  setSaveCallEnabled,
  setIsPostCall,
}) => {
  const apolloClient = useApolloClient();
  const [isSavingCall, setIsSavingCall] = useState(false);
  const [showPostCallButtons, setShowPostCallButtons] = useState(false);
  const selectedPatient = patient;
  const patientName = patient && patient.patientName;

  useEffect(() => {
    if (isPostCall) {
      setShowPostCallButtons(true);
    } else {
      setShowPostCallButtons(false);
    }
  }, [isPostCall]);

  const [updatePhone] = useMutation(UPDATE_PHONE, {
    onCompleted: async data => {
      const updatedPhoneId = _get(data, 'updatePhone.data.id');
      setSaveCallEnabled(isPostCall);
      await onSuccessSaveNumber(updatedPhoneId, callId);
    },
    onError(error) {
      message.error(`Error when saving phone: ${error.message}`);
    },
  });

  const [updateCallRecordMutation] = useMutation(UPDATE_CALL_RECORD, {
    onError: error => {
      message.error(`Error when updating call record: ${error.message}`);
    },
  });

  const [saveCallToPatientActivityMutation] = useMutation(SAVE_CALL_TO_ACTIVITY, {
    onCompleted: async () => {
      const phoneId = await updateCallRecord();
      setSaveCallEnabled(isPostCall);
      await onSuccessSaveNumber(phoneId, callId);
      setIsSavingCall(false);
    },
    onError: error => {
      message.error(`Error when saving call to activity: ${error.message}`);
    },
  });

  const saveCall = async () => {
    setIsSavingCall(true);
    await saveCallToPatientActivityMutation({
      variables: {
        call_id: callId,
        patient_id: patientId !== selectedPatient && selectedPatient.id ? selectedPatient.id : patientId,
      },
    });
  };

  const cancelInnerModal = () => {
    setShowPostCallButtons(true);
  };

  const cancelPostCall = () => {
    setIsPostCall(false);
    onCancel();
  };

  const cancelSaveModal = () => {
    onCancel();
    setSaveCallEnabled(true);
  };

  const updateCallRecord = async () => {
    const callRecord = await getCallRecordQuery(apolloClient, parseInt(callId, 10));
    const updateCallVariables = {
      call_id: _get(callRecord, 'id'),
    };

    let phoneId;
    if (_get(callRecord, 'direction') === 'inbound') {
      phoneId = _get(callRecord, 'from_phone_id');
      updateCallVariables.from_phone_id = _get(callRecord, 'from_phone_id');
    } else {
      phoneId = _get(callRecord, 'to_phone_id');
      updateCallVariables.to_phone_id = _get(callRecord, 'to_phone_id');
    }

    await updateCallRecordMutation({
      variables: updateCallVariables,
    });
    return phoneId;
  };

  const renderSaveCallForm = () => {
    return (
      <Modal
        title={
          <div className={css(sx.flex, sx.alignCenter)}>
            <HandIcon />
            <div className={css(commonSx.ml10)}>Save Call to Member</div>
          </div>
        }
        open={isModalOpen}
        onCancel={() => {
          onCancel();
        }}
        maskClosable={false}
        footer={null}
        afterClose={afterClose}
      >
        <div className={css(sx.flex, sx.flex1, sx.width100, sx.form)}>
          <div className={css(sx.pb80)}>
            Are you sure you want to save this call to
            <b>{` ${patientName}`}</b>?
          </div>

          <div className={css(sx.flex)}>
            <Button
              loading={isSavingCall}
              type="primary"
              className={css(sx.flex1, sx.actionBtn)}
              onClick={saveCall}
            >
              Save
            </Button>

            <Button
              className={css(sx.flex1, sx.actionBtn, sx.cancelBtn)}
              onClick={
                isPostCall
                  ? () => {
                      cancelInnerModal();
                    }
                  : () => {
                      cancelSaveModal();
                    }
              }
            >
              {isPostCall ? 'Back' : 'Cancel'}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderSaveCallAndNumberForm = () => {
    return (
      <Formik
        initialValues={{
          number: number ? number.slice(2) : '',
          label: '',
          extension: '',
          can_sms: false,
        }}
        validate={values => {
          const errors = {};
          if (!values.number) {
            errors.number = 'Required';
          }

          if (!values.label) {
            errors.label = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { number: newNumber, label, extension, can_sms } = values;

          const newPhone = {
            number: `+1${newNumber}`,
          };

          if (label) {
            newPhone.label = label;
          }

          if (extension) {
            newPhone.extension = extension;
          }

          if (can_sms) {
            newPhone.can_sms = can_sms;
          }

          setSubmitting(true);

          const phoneId = await updateCallRecord();

          await updatePhone({
            variables: {
              id: phoneId,
              modifier: {
                patient_id: patientId !== selectedPatient && selectedPatient.id ? selectedPatient.id : patientId,
                number: newPhone.number,
                label: newPhone.label,
                can_sms: newPhone.can_sms,
                extension: newPhone.extension,
              },
            },
          });
        }}
        enableReinitialize
      >
        {({ isSubmitting, errors, submitForm, resetForm, setFieldValue }) => {
          return (
            <Modal
              title={
                <div className={css(sx.flex, sx.alignCenter)}>
                  <HandIcon />
                  <div className={css(commonSx.ml10)}>Add New Number</div>
                </div>
              }
              open={isModalOpen}
              onCancel={() => {
                onCancel();
              }}
              maskClosable={false}
              footer={null}
              afterClose={afterClose}
            >
              <Form className={css(sx.flex, sx.flex1, sx.width100, sx.form)}>
                <div>
                  Are you sure you want to save this call and number to
                  <b>{` ${patientName}`}</b>?
                </div>

                <div>
                  <div className={css(sx.mtb10, errors.label ? sx.inputLabelError : null)}>
                    Custom Label
                  </div>

                  <Field
                    name="label"
                    placeholder="Other"
                    className={css(sx.otherStyles, errors.label ? sx.inputError : null)}
                  />

                  <div className={css(sx.flex)}>
                    <div className={css(sx.numberContainer)}>
                      <div className={css(sx.mtb10, errors.number ? sx.inputLabelError : null)}>
                        Number
                      </div>

                      <Field name="number">
                        {({ field }) => (
                          <InputMask
                            {...field}
                            className={css(
                              sx.otherStyles,
                              sx.width100,
                              errors.number ? sx.inputError : null,
                            )}
                            mask="(999) 999-9999"
                            onChange={e => {
                              // removes all non digit characters
                              const val = _get(e, 'target.value').replace(/\D/g, '');
                              setFieldValue('number', val);
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    <div className={css(sx.flex1)}>
                      <div className={css(sx.mtb10)}>Ext</div>
                      <Field
                        name="extension"
                        style={{ width: '100%' }}
                        placeholder="123"
                        className={css(sx.otherStyles)}
                      />
                    </div>
                  </div>

                  <div className={css(sx.flex, sx.mtb10, sx.alignCenter)}>
                    <Field type="checkbox" name="can_sms" />

                    <div className={css(commonSx.ml10)}>Authorized for SMS</div>
                  </div>
                </div>

                <div className={css(sx.flex)}>
                  <Button
                    loading={isSubmitting}
                    type="primary"
                    className={css(sx.flex1, sx.actionBtn)}
                    onClick={submitForm}
                  >
                    Save
                  </Button>

                  <Button
                    className={css(sx.flex1, sx.actionBtn, sx.cancelBtn)}
                    onClick={
                      isPostCall
                        ? () => {
                            cancelInnerModal();
                          }
                        : () => {
                            resetForm();
                            cancelSaveModal();
                          }
                    }
                  >
                    {isPostCall ? 'Back' : 'Cancel'}
                  </Button>
                </div>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  };

  const renderPostCallButtons = () => {
    return (
      <Modal
        title={
          <div className={css(sx.flex, sx.alignCenter)}>
            <HandIcon />
            <div className={css(commonSx.ml10)}>Save Call or Add Number to Member</div>
          </div>
        }
        open={isModalOpen}
        onCancel={() => cancelPostCall()}
        maskClosable={false}
        footer={null}
        afterClose={afterClose}
      >
        <div className={css(sx.flex, sx.flex1, sx.width100, sx.form)}>
          <div className={css(sx.pb80)}>
            <div className={css(sx.pb20, sx.justifyCenter)}>
              <Button
                type="purple"
                className={css([commonSx.bold, sx.mtb10])}
                onClick={() => {
                  setSaveCallOnly(true);
                  setShowPostCallButtons(false);
                }}
              >
                {`Save call to ${patientName}`}
              </Button>
            </div>
            <div className={css(sx.pb20, sx.justifyCenter)}>
              <Button
                type="purple"
                className={css([commonSx.bold, sx.mtb10])}
                onClick={() => {
                  setSaveCallOnly(false);
                  setShowPostCallButtons(false);
                }}
              >
                {`Save call and number to ${patientName}`}
              </Button>
            </div>
          </div>

          <div className={css(sx.flex)}>
            <Button
              className={css(sx.flex1, sx.actionBtn, sx.cancelBtn)}
              onClick={() => cancelPostCall()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={css(sx.flex, sx.saveNumberContainer)}>
      {showPostCallButtons && renderPostCallButtons()}
      {!showPostCallButtons &&
        (saveCallOnly ? renderSaveCallForm() : renderSaveCallAndNumberForm())}
    </div>
  );
};

SaveNumber.propTypes = {
  callId: PropTypes.number,
  saveCallOnly: PropTypes.bool,
  number: PropTypes.string,
  patientId: PropTypes.number,
  patient: PropTypes.object,
  onCancel: PropTypes.func,
  isModalOpen: PropTypes.bool,
  onSuccessSaveNumber: PropTypes.func,
  afterClose: PropTypes.func,
  isPostCall: PropTypes.bool,
  setSaveCallOnly: PropTypes.func,
  setSaveCallEnabled: PropTypes.func,
  setIsPostCall: PropTypes.func,
};

export default SaveNumber;
