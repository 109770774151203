/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { cloneDeep, omit, isEmpty, merge } from 'lodash';
import { Table, Button, Typography, Tag, Tooltip, message } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import withRoles from 'hoc/withRoles';
import filterSearchBox from '../Filters/Search/Search';
import {
  GET_POTENTIAL_AND_ASSIGNED_POSTALCODES,
  DELETE_POTENTIAL_PHARMACY,
  DELETE_ASSIGNED_PHARMACY,
  GET_PATIENT_COUNT_BY_POSTAL_CODE,
  GET_ZIPCODE_COVERAGE_REPORT_FILE,
} from '../../graphql/zipcodeManagementCalls';
import DeleteModal from './DeleteModal/DeleteModal';
import BulkRemoveConfirmationModal from './DeleteModal/BulkRemoveConfirmationModal';
import AddPotentialPharmacyModal from './AddPotentialPharmacyModal';
import AddAssignedPharmacyModal from './AddAssignedPharmacyModal';
import BulkRemoveZipcodesModal from './BulkRemoveZipcodesModal';
import DownloadZipcodeReportModal from './DownloadZipcodeReportModal';
import generateVariables from '../../Utils/GenerateVariables.utils';
import states from '../../constants/states';

const sx = StyleSheet.create({
  autoComplete: { width: '188px', marginBottom: '8px', display: 'block' },
  searchContainer: {
    padding: '8px',
  },
  searchIcon: {
    fontSize: '15px',
  },
  searchIconActive: {
    fontSize: '15px',
    color: '#5009B5',
  },
  searchButton: { width: '90px' },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '250px',
    height: '22px',
    marginBottom: '8px',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500',
  },
  tagText1: {
    color: '#231E33',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tagText2: {
    color: '#FFFFFF',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  buttonsContainer: {
    marginBottom: '20px',
  },
  buttonMargin: {
    marginRight: '16px',
  },
});

const { Text } = Typography;

const ZipCodeAssignmentTable = ({ isPharmacyTeam, isSystemAdmin }) => {
  const [deleteType, setDeleteType] = useState('');
  const [rowData, setRowData] = useState({});
  const [selectedTag, setSelectedTag] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bulkDeletedZipCodes, setBulkDeletedZipCodes] = useState([]);
  const [bulkDeletedZipCodesType, setBulkDeletedZipCodesType] = useState('assigned');
  const [tableData, setTableData] = useState([]);
  const [tableActions, setTableActions] = useState({});
  const [viewAddPotentialPharmacyModal, setViewAddPotentialPharmacyModal] = useState(false);
  const [viewAddAssignedPharmacyModal, setViewAddAssignedPharmacyModal] = useState(false);
  const [viewBulkRemoveZipcodesModal, setViewBulkRemoveZipcodesModal] = useState(false);
  const [viewBulkRemoveConfirmationModal, setViewBulkRemoveConfirmationModal] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [isRefetched, setIsRefetched] = useState(false);
  const [patientCount, setPatientCount] = useState(null);
  const [showDownloadZipcodeReportModal, setShowDownloadZipcodeReportModal] = useState(false);

  const [
    getPotentialAndAssignedList,
    { loading: isFetchingPotentialAndAssignedList },
  ] = useLazyQuery(GET_POTENTIAL_AND_ASSIGNED_POSTALCODES, {
    fetchPolicy: 'no-cache',
    onError: error => {
      message.error(`Error fetching potential and assigned postal codes: ${error.message}`);
    },
    onCompleted: ({ potentialAndAssignedPostalCodes }) => {
      if (potentialAndAssignedPostalCodes?.data) {
        generateTableData(potentialAndAssignedPostalCodes?.data);
      }
      setTotalCount(potentialAndAssignedPostalCodes?.total);
    },
  });

  const [deletePotentialPostalCode] = useMutation(DELETE_POTENTIAL_PHARMACY, {
    onError: error => {
      message.error(`Error Deleting Potential Pharmacy: ${error.message}`);
    },
    onCompleted: () => {
      message.success(`Potential Pharmacy has been successfully deleted`);
      setShowDeleteModal(false);
      fetchZipcodeData();
    },
  });

  const [deletePostalCode] = useMutation(DELETE_ASSIGNED_PHARMACY, {
    onError: error => {
      message.error(`Error Deleting Assigned Pharmacy: ${error.message}`);
    },
    onCompleted: () => {
      message.success(`Assigned Pharmacy has been successfully deleted`);
      setShowDeleteModal(false);
      fetchZipcodeData();
    },
  });

  const [getPatientCountByPostalCode, { loading: isPatientCountLoading }] = useLazyQuery(
    GET_PATIENT_COUNT_BY_POSTAL_CODE,
    {
      fetchPolicy: 'no-cache',
      onError: error => {
        message.error(`Error fetching patient count: ${error.message}`);
      },
      onCompleted: ({ getPatientCountWithPostalCoverage }) => {
        if (getPatientCountWithPostalCoverage?.data) {
          setPatientCount(getPatientCountWithPostalCoverage?.data);
        }
      },
    },
  );

  const [getReportUrl] = useLazyQuery(GET_ZIPCODE_COVERAGE_REPORT_FILE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching attachment: ${error.message}`);
    },
    onCompleted: ({ getZipcodeCoverageReportFile }) => {
      const url = getZipcodeCoverageReportFile.data?.file_url;
      window.open(url, '_blank');
      setShowDownloadZipcodeReportModal(false);
    },
  });

  useEffect(() => {
    if (isSystemAdmin || isPharmacyTeam) {
      fetchZipcodeData();
    }
  }, []);

  const generateTableData = preData => {
    const data = preData.reduce((acc, val) => {
      const current = omit({ ...val, anthemAssignedPharmacies: [] }, ['assignedPharmacies']);
      const { assignedPharmacies } = val;
      if (assignedPharmacies) {
        assignedPharmacies.forEach(p => {
          if (p?.payer?.name === 'Anthem') {
            current.anthemAssignedPharmacies.push(p);
          }
        });
      }
      acc.push(current);
      return acc;
    }, []);
    setTableData(data);
  };

  const checkIfFiltersApplied = () => {
    const filters = tableActions?.filters;
    const filtersEmpty = isEmpty(filters) || Object.keys(filters).every(e => filters[e] === null);
    return !filtersEmpty;
  };

  const cleanFilters = filters => {
    let newFilters = {};
    if (filters && !isEmpty(filters)) {
      // Diff between new and old keys to see what key was updated.
      newFilters = merge(tableActions.filters, filters);
    }
    // const excludedFiltersApplied = newFilters.state && newFilters.zipcode;
    // if (excludedFiltersApplied) {
    //   if (oldFilters.state) {
    //     newFilters.state = null;
    //   } else if (oldFilters.zipcode) {
    //     newFilters.zipcode = null;
    //   }
    // }

    return newFilters;
  };

  const onTableChange = (pagination, filters, sorter, { action }) => {
    const cleanedFilters = action === 'filter' ? cleanFilters(filters) : tableActions?.filters;

    setTableActions({
      pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
      filters: cleanedFilters,
    });

    if (action === 'paginate') {
      fetchZipcodeData({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchZipcodeData({
        pagination: {...pagination, current: 1},
        filters: cleanedFilters,
        sorter,
      });
    }
  };

  const onClickDelete = (e, type, pharmacy, record) => {
    e.preventDefault();
    setDeleteType(type);
    setSelectedTag(pharmacy);
    getPatientCountByPostalCode({
      variables: {
        postalCode: record?.postal_code,
        pharmacyId: pharmacy.id,
      },
    });
    if (record) {
      setRowData(record);
    }
    setShowDeleteModal(true);
  };

  const onDeleteOk = (type, selectedTag) => {
    if (type === 'Potential Pharmacy') {
      deletePotentialPostalCode({
        variables: {
          id: selectedTag.zipcodeId,
        },
      });
    } else {
      deletePostalCode({
        variables: {
          id: selectedTag.zipcodeId,
        },
      });
    }
  };

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const downloadZipcodeReportCancel = () => {
    setShowDownloadZipcodeReportModal(false);
  };

  const downloadReportConfirmation = () => {
    setShowDownloadZipcodeReportModal(true);
  };

  const fetchZipcodeData = (payload = cloneDeep(tableActions), refetch = false) => {
    if (!refetch) {
      const variables = generateVariables(payload);
      if (payload?.sorter && !payload?.sorter?.order) {
        payload.sorter = null;
      }
      getPotentialAndAssignedList({
        variables: {
          ...variables,
        },
      });
    } else {
      getPotentialAndAssignedList({
        variables: {
          query: {
            potential_postal_code: { $in: payload },
            postal_code: { $in: payload },
          },
        },
      });
      setIsRefetched(true);
    }
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: tableData,
      size: 'middle',
      onChange: onTableChange,
      rowKey: record => record.id,
      rowClassName: record => record.dismissed_at !== null && css(sx.disabledRow),
      loading: isFetchingPotentialAndAssignedList,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: totalCount || 0,
        current: tableActions?.pagination?.current || 1,
        pageSize: tableActions?.pagination?.pageSize,
      },
    };

    return tableOptions;
  };

  const generateColumns = () => {
    const columns = [
      {
        title: 'Zip Code',
        dataIndex: 'postal_code',
        key: 'zipcode',
        width: '15%',
        align: 'left',
        ...filterSearchBox(
          'zipcode',
          'Zip Code',
          tableActions?.filters?.zipcode,
          tableActions,
          setTableActions,
          fetchZipcodeData,
        ),
        filteredValue: tableActions?.filters?.zipcode || null,
        render: record => {
          return record;
        },
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: '15%',
        align: 'left',
        filters: states,
        // filterMultiple: false,
        filteredValue: tableActions?.filters?.state || null,
      },
      {
        title: 'Potential Pharmacy',
        dataIndex: 'potentialPharmacies',
        key: 'potentialPharmacies',
        width: '25%',
        align: 'left',
        filteredValue: tableActions?.filters?.potentialPharmacies || null,
        render: (potentialPharmacies, record) => {
          return (
            <>
              {potentialPharmacies?.map(postalCode => {
                return (
                  <Tooltip title={`${postalCode.name} ${postalCode.npi}`}>
                    <Tag
                      className={css(sx.box)}
                      key={postalCode.zipcodeId}
                      closable
                      onClose={e => onClickDelete(e, 'Potential Pharmacy', postalCode, record)}
                    >
                      <Text
                        className={css(sx.tagText1)}
                      >{`${postalCode.name} ${postalCode.npi}`}</Text>
                    </Tag>
                  </Tooltip>
                );
              })}
            </>
          );
        },
        ...filterSearchBox(
          'potentialPharmacies',
          'Potential Pharmacy',
          tableActions?.filters?.potentialPharmacies,
          tableActions,
          setTableActions,
          fetchZipcodeData,
        ),
      },
      {
        title: 'Anthem Assigned Pharmacy',
        dataIndex: 'anthemAssignedPharmacies',
        key: 'anthemAssignedPharmacies',
        width: '25%',
        align: 'left',
        filteredValue: tableActions?.filters?.anthemAssignedPharmacies || null,
        render: (anthemAssignedPharmacies, record) => {
          return (
            <>
              {anthemAssignedPharmacies?.map(postalCode => {
                return (
                  <Tooltip title={`${postalCode.name} ${postalCode.npi}`}>
                    <Tag
                      className={css(sx.box)}
                      key={postalCode.zipcodeId}
                      closable
                      color="#494949"
                      onClose={e => onClickDelete(e, 'Assigned Pharmacy', postalCode, record)}
                    >
                      <Text
                        className={css(sx.tagText2)}
                      >{`${postalCode.name} ${postalCode.npi}`}</Text>
                    </Tag>
                  </Tooltip>
                );
              })}
            </>
          );
        },
        ...filterSearchBox(
          'anthemAssignedPharmacies',
          'Anthem Assigned Pharmacies',
          tableActions?.filters?.anthemAssignedPharmacies,
          tableActions,
          setTableActions,
          fetchZipcodeData,
        ),
      },
      {
        title: '',
        width: '20%',
        align: 'center',
      },
    ];
    return columns;
  };

  const handleAddPotentialPharmacy = () => {
    setViewAddPotentialPharmacyModal(true);
  };

  const handleAddAssignedPharmacy = () => {
    setViewAddAssignedPharmacyModal(true);
  };

  const handleBulkRemoveZipcodes = () => {
    setViewBulkRemoveZipcodesModal(true);
  };

  const onClosePotentialPharmacyModal = () => {
    setViewAddPotentialPharmacyModal(false);
  };

  const onCloseAssignedPharmacyModal = () => {
    setViewAddAssignedPharmacyModal(false);
  };

  const onCloseBulkRemoveZipcodesModal = (zipcodesType = 'assigned', zipcodes = []) => {
    setBulkDeletedZipCodesType(zipcodesType);
    setViewBulkRemoveZipcodesModal(false);
    if (zipcodes?.length > 0) {
      setBulkDeletedZipCodes(zipcodes);
      setViewBulkRemoveConfirmationModal(true);
    }
  };

  const onCloseBulkDeleteConfirmationModal = () => {
    setViewBulkRemoveConfirmationModal(false);
    setBulkDeletedZipCodesType('assigned');
    setBulkDeletedZipCodes([]);
    fetchZipcodeData();
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: {...tableActions.pagination, current: 1},
      filters: null,
      sorter: null,
    };

    setTableActions(payload);
    fetchZipcodeData(payload);
    setIsRefetched(false);
  };

  return (
    <>
      <div className={css(sx.buttonsContainer)}>
        <Button
          type="secondary"
          className={css(sx.buttonMargin)}
          onClick={handleAddPotentialPharmacy}
        >
          Bulk Add Potential Pharmacy
        </Button>
        <Button
          type="secondary"
          className={css(sx.buttonMargin)}
          onClick={handleAddAssignedPharmacy}
        >
          Bulk Add Assigned Pharmacy
        </Button>
        <Button
          type="secondary"
          className={css(sx.buttonMargin)}
          onClick={handleBulkRemoveZipcodes}
        >
          Bulk Remove Zipcodes
        </Button>
        {isPharmacyTeam || isSystemAdmin ? (
          <Button
            type="secondary"
            className={css(sx.buttonMargin)}
            onClick={downloadReportConfirmation}
          >
            Download Report
          </Button>
        ) : null}
        {(checkIfFiltersApplied() || isRefetched) && (
          <Button type="secondary" className={css(sx.buttonMargin)} onClick={clearAllFilters}>
            Clear All Filters
          </Button>
        )}
      </div>

      <Table {...getTableOptions()} />
      {showDeleteModal && !isPatientCountLoading && (
        <DeleteModal
          onDeleteCancel={onDeleteCancel}
          onDeleteOk={onDeleteOk}
          rowData={rowData}
          selectedTag={selectedTag}
          type={deleteType}
          visible={showDeleteModal}
          patientCount={patientCount}
        />
      )}

      <AddPotentialPharmacyModal
        onCloseModal={() => onClosePotentialPharmacyModal()}
        refetchZipcodeTable={fetchZipcodeData}
        visible={viewAddPotentialPharmacyModal}
      />

      <AddAssignedPharmacyModal
        onCloseModal={() => onCloseAssignedPharmacyModal()}
        refetchZipcodeTable={fetchZipcodeData}
        visible={viewAddAssignedPharmacyModal}
      />

      {viewBulkRemoveZipcodesModal && (
        <BulkRemoveZipcodesModal
          onCloseModal={(zipcodesType, zipcodes) =>
            onCloseBulkRemoveZipcodesModal(zipcodesType, zipcodes)
          }
          zipcodeType={'assigned'}
          visible={viewBulkRemoveZipcodesModal}
        />
      )}

      {viewBulkRemoveConfirmationModal && bulkDeletedZipCodes.length > 0 && (
        <BulkRemoveConfirmationModal
          zipcodeType={bulkDeletedZipCodesType}
          zipcodes={bulkDeletedZipCodes.join(', ')}
          onCloseModal={() => onCloseBulkDeleteConfirmationModal()}
          visible={viewBulkRemoveConfirmationModal}
        />
      )}
      <DownloadZipcodeReportModal
        downloadZipcodeReportCancel={downloadZipcodeReportCancel}
        downloadZipcodeReport={getReportUrl}
        visible={showDownloadZipcodeReportModal}
      />
    </>
  );
};

export default withRoles(ZipCodeAssignmentTable);
